const BASE_URL = "https://qa.skillsbridge.ai/api"; // Base URL for API
// const BASE_URL = "http://127.0.0.1:5000/api"; // Base URL for testing locally

if (process.env.NODE_ENV === 'production') {
  //BASE_URL = process.env.REACT_APP_API_BASE_URL;
}

export const API_ENDPOINTS = {
  ROLES: `${BASE_URL}/roles`,
  VERSIONS: `${BASE_URL}/versions`,
  SB_JOB_DESCRIPTION: `${BASE_URL}/sb_job_description`,
  ORIGINAL_JOB_DESCRIPTION: `${BASE_URL}/original_job_description`,

  AUTOMATION_POTENTIAL: `${BASE_URL}/automation_potential`,
  COMPARE_DATA: `${BASE_URL}/compare_data`,
  UPLOAD: `${BASE_URL}/upload`,
  ANALYZE_JD: `${BASE_URL}/analyze_jd`,
  GET_UPLOADED_FILE: `${BASE_URL}/get_uploaded_file`,
  READ_FROM_BIGQUERY: `${BASE_URL}/bq/read`,
  UPDATE_BIGQUERY: `${BASE_URL}/bq/update`,
  // Add more endpoints here as needed
};
